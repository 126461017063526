<template>
  <div
    v-infinite-scroll="loadMore"
    infinite-scroll-disabled="busy"
    infinite-scroll-distance="10"
  >
    <r-page>
      <template #page-header>
        <h2 class="page-title">
          People
          <span class="people-subheader">({{ getTotal }} Employees)</span>
        </h2>
        <r-menu-tab :level="2"></r-menu-tab>
      </template>
      <template #action-bar>
        <PeopleHeader
          :filter="true"
          :search="true"
          :pageMenu="'people'"
          :open="isFilterOpen"
          :statusIds="statusIds"
          :locationIds="locationIds"
          :departmentIds="departmentIds"
          :isRemote="isRemote"
          @onFiltered="isFilterOpen = !isFilterOpen"
          @onSearch="searchIconClick"
          @onReset="resetFilter"
          @onFilter="onFilter($event)"
        />
      </template>
      <template #page-content>
        <!-- Card View -->
        <div v-if="!isViewedAsTable">
          <div class="columns is-multiline mt-2">
            <div
              class="column is-one-fifth card-people"
              v-for="(item, index) in peopleData"
              :key="index"
              @click="selectEmployee(item)"
            >
              <div class="card-people-ribbon">{{ item.departmentName }}</div>
              <img
                :src="
                  determineProfilePicture(item.profilePictureUrl, item.gender)
                "
                alt="Profile"
                class="card-people-image"
              />
              <p class="card-people-name">
                {{ item.fullName }}
              </p>
              <p class="card-people-email">{{ item.workEmail }}</p>
              <div class="is-flex is-justify-content-center">
                <div class="card-people-department">
                  <span class="card-people-content">
                    {{ item.positionName }}
                  </span>
                  <span class="card-people-content ml-1">
                    {{ formatBirthday(item.dateOfBirth) }}
                  </span>
                  <b-icon
                    icon="party-popper"
                    class="birthday-icon"
                    v-if="isOnBirthday(item.dateOfBirth)"
                  ></b-icon>
                </div>
              </div>
              <p class="card-people-content">
                Status: {{ formatProbation(item.probationEndAt) }}
              </p>
            </div>
            <b-loading
              :is-full-page="isFullPage"
              v-model="isPeopleDataLoading"
              :can-cancel="true"
            ></b-loading>
            <section
              class="section column is-12 mt-6"
              v-if="
                peopleData && peopleData.length === 0 && !isPeopleDataLoading
              "
            >
              <div class="content has-text-grey has-text-centered">
                <p>No data found</p>
              </div>
            </section>
          </div>
        </div>

        <!-- Table View -->
        <div
          id="table-request-employment"
          class="table-timeoff-type"
          v-if="isViewedAsTable"
        >
          <b-table
            :data="peopleData"
            :per-page="perPage"
            :loading="isPeopleDataLoading"
            @page-change="onPageChange"
            ref:table
            hoverable
            narrowed
            backend-sorting
            :default-sort-direction="defaultSortOrder"
            :default-sort="[sortField, sortOrder]"
            class="employment-directory-table general-table"
            @sort="onSort"
            :sticky-header="stickyHeaders"
            :selected.sync="selectedEmployee"
          >
            <b-table-column
              field="employments.id"
              label="Employee ID"
              v-slot="props"
              sortable
              width="8%"
            >
              <span class="is-capitalize">
                {{ props.row.employmentId }}
              </span>
            </b-table-column>

            <b-table-column
              field="users.first_name"
              label="Full Name"
              v-slot="props"
              sortable
              width="26%"
            >
              <div class="columns employment-table-name">
                <img
                  class="log-activity-profile-picture"
                  :src="
                    determineProfilePicture(
                      props.row.profilePictureUrl,
                      props.row.gender
                    )
                  "
                />
                <div
                  class="is-capitalize is-flex is-justify-content-flex-start is-align-items-center"
                >
                  <div class="mr-2">
                    {{ props.row.fullName }}
                  </div>
                  <b-tag
                    type="is-danger"
                    class="has-text-white has-text-weight-bold mr-4 is-rounded"
                    size="is-small"
                    v-if="determineNewEmployee(props.row.joinedAt)"
                  >
                    New
                  </b-tag>
                  <b-tag
                    v-if="formatBirthday(props.row.dateOfBirth, false)"
                    class="has-text-white has-text-weight-bold"
                    type="is-info"
                  >
                    {{ formatBirthday(props.row.dateOfBirth, false) }}
                  </b-tag>
                </div>
              </div>
            </b-table-column>

            <b-table-column
              field="positions.position_name"
              label="Position"
              v-slot="props"
              sortable
              width="10%"
            >
              <span class="is-capitalize">
                {{ props.row.positionName }}
              </span>
            </b-table-column>

            <b-table-column
              field="users.work_email"
              label="Work Email"
              v-slot="props"
              sortable
              width="12%"
            >
              <span>
                {{ props.row.workEmail }}
              </span>
            </b-table-column>

            <b-table-column
              field="users.working_statuses.working_status_name"
              label="Status"
              v-slot="props"
              sortable
              width="6%"
            >
              <span
                :class="`${determineFontColorByStatus(
                  props.row.workingStatus
                )}`"
              >
                {{ props.row.workingStatus }}
              </span>
            </b-table-column>

            <b-table-column
              field="employments.probation_end_at"
              label="Probation Date"
              v-slot="props"
              sortable
              width="12%"
              v-if="currentUser.isAdmin"
            >
              <span>
                {{ formatDate(props.row.probationEndAt) }}
              </span>
            </b-table-column>

            <b-table-column
              field="employments.end_contract_at"
              label="Contract End Date"
              v-slot="props"
              sortable
              width="12%"
              v-if="currentUser.isAdmin"
            >
              <span>
                {{ formatDate(props.row.endContractAt) }}
              </span>
            </b-table-column>
            <!--            <template slot="empty">-->
            <!--              <section class="section">-->
            <!--                <div class="content has-text-grey has-text-centered">-->
            <!--                  <p>No data foundz</p>-->
            <!--                  <p>People data : {{peopleData}}</p>-->
            <!--                </div>-->
            <!--              </section>-->
            <!--            </template>-->
          </b-table>
        </div>

        <!-- Sidebar -->
        <RightBarContentPeopleDetail
          :people="selectedPeople"
          :isSideBarOpen="isSideBarOpen"
          :closeSidebar="closeSidebar"
          :determineProfilePicture="determineProfilePicture"
          :formatDate="formatDate"
          :formatBirthday="formatBirthday"
          :formatCountDown="formatCountDown"
          :isViewedAsTable="isViewedAsTable"
          :determine-new-employee="determineNewEmployee"
        />
      </template>
    </r-page>
  </div>
</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import RightBarContentPeopleDetail from './People/RightBarContentPeopleDetail'
// import FilterVue from '../components/Filter.vue'
// import DepartmentFilterVue from '../components/FilterContent/DepartmentFilter.vue'
// import PeopleStatusFilter from '../components/FilterContent/PeopleStatusFilter.vue'
// import LocationFilter from '../components/FilterContent/LocationFilter.vue'
import PeopleHeader from '../components/Header/PeopleHeader.vue'

export default {
  name: 'People',
  components: {
    RightBarContentPeopleDetail,
    // FilterVue,
    // DepartmentFilterVue,
    // PeopleStatusFilter,
    // LocationFilter,
    PeopleHeader,
  },

  data() {
    return {
      search: null,
      startDate: '',
      endDate: '',
      isViewedAsTable: true,
      isSideBarOpen: false,
      isSearching: false,
      isFullPage: true,

      // filter
      isFilterOpen: false,
      isRemote: null,

      // filter content
      // ------- location --------
      mainLocation: null,
      locationIds: [],
      // ------- department --------
      departmentsList: null,
      departmentIds: [],
      // ------- status ----------
      statusList: null,
      statusIds: [],

      // table view
      stickyHeaders: true,
      perPage: 20,
      page: 0,
      lastPage: 0,
      total: 0,
      defaultSortOrder: 'asc',
      sortField: 'employments.id',
      sortOrder: 'asc',
      isPeopleDataLoading: false,
      isFilterListLoading: false,
      isLoadMore: false,
      selectedEmployee: null,

      selectedPeople: null,
    }
  },
  async mounted() {
    this.resetState()
    await this.loadPeopleDataList()
    await this.loadFilterList()
  },
  computed: {
    ...mapGetters({
      peopleData: 'user/getPeopleListData',
      currentUser: 'user/getUserInfo',
      filterData: 'user/getPeoplesFilter',
    }),
    getTotal() {
      return this.total
    },
  },
  methods: {
    ...mapActions({
      actionFetchPeopleList: 'user/fetchPeopleList',
      actionFetchPeoplesFilter: 'user/fetchPeoplesFilter',
    }),
    ...mapMutations({
      setPeopleList: 'user/setPeopleList',
    }),

    /**
     * Determine if user new employee or not
     * @param {string} date - date employee joined company
     * @return {bool}
     */
    determineNewEmployee(date) {
      let momentCurrent = moment()
      let joinDate = moment(date)
      let diff = momentCurrent.diff(joinDate, 'days')
      // 1 month duration since join
      if (diff <= 31) {
        return true
      }
      return false
    },

    /**
     * Handle to close sidebar
     */
    closeSidebar() {
      this.isSideBarOpen = false
      this.selectedPeople = null
    },

    /**
     * Convert date into designated string
     * @return {string}
     */
    formatProbation(date) {
      const today = moment(new Date())
      const end = moment(new Date(date))

      if (moment(today).isAfter(end)) {
        return '-'
      } else {
        return 'Probation'
      }
    },

    /**
     * Convert date string into countdown happy birthday
     * @param {string} date - people date
     * @param {string} date - people date
     * @return {string} formatted title case string
     */
    formatBirthday(date, isSidebar) {
      const today = moment()
      const year = moment().year()
      const birthMonth = moment(date).month()
      const birthDate = moment(date).date()
      const birthday = moment([year, birthMonth, birthDate])

      const diff = birthday.startOf('day').diff(today.startOf('day'), 'days')
      if (isSidebar) {
        if (diff === 0) {
          return `Happy Birthday!`
        } else if (diff === 1) {
          return `${diff} day to birthday`
        } else if (diff > 1 && diff <= 7) {
          return `${diff} days to birthday`
        } else {
          return null
        }
      } else {
        if (diff === 0) {
          return `Happy Birthday!`
        } else if (diff === 1) {
          return `${diff} day to birthday`
        } else if (diff > 1 && diff <= 7) {
          return `${diff} days to birthday`
        } else {
          return null
        }
      }
    },

    /**
     * Convert date string into count down string
     * @param {string} date - people date
     * @return {string} formatted countdown string
     */
    formatCountDown(date) {
      const today = moment(new Date())
      const target = moment(new Date(date))

      const diff = target.startOf('day').diff(today.startOf('day'), 'days')
      if (diff === 0) {
        return `Last day!`
      } else if (diff === 1) {
        return `in ${diff} day`
      } else if (diff > 1 && diff <= 90) {
        return `in ${diff} days`
      } else {
        return '-'
      }
    },

    /**
     * determined if user on birthday
     * @param {string} date - people date
     * @return {bool}
     */
    isOnBirthday(date) {
      const today = moment(new Date()).format('YYYY-MM-DD')
      const year = moment().year()
      const birthMonth = moment(date).month()
      const birthDate = moment(date).date()
      const birthday = moment([year, birthMonth, birthDate]).format(
        'YYYY-MM-DD'
      )

      if (today === birthday) {
        return true
      } else {
        return false
      }
    },

    /**
     * Format date into designated string
     * @param {string} str - people date
     * @return {string}
     */
    formatDate(str) {
      const d = new Date(str)
      return moment(d).format('DD MMMM YYYY')
    },

    /**
     * Convert string into designated string
     * @param {string} str - people date
     * @return {string}
     */
    determineFontColorByStatus(status) {
      if (status === 'online') {
        return 'status-online'
      }

      if (status === 'offline') {
        return 'status-offline'
      }

      if (status === 'leave') {
        return 'status-leave'
      }
    },

    /**
     * Return user profile picture by gender
     * @param {string} string - url profile picture
     * @return {string}
     */
    determineProfilePicture(picture, gender) {
      if (!picture) {
        if (gender === 'F') {
          // female
          return '/images/default-profile-picture-female.png'
        }
        // male
        return '/images/default-profile-picture-male.png'
      } else {
        return picture
      }
    },

    /**
     * Return user profile picture by gender
     * @param {object} user - user object data
     */
    selectEmployee(user) {
      this.selectedPeople = user
      this.isSideBarOpen = true
    },

    /**
     * Function to Load more data
     */
    async loadMore() {
      if (this.page < this.lastPage) {
        this.isLoadMore = true
        await this.loadPeopleDataList()
        this.isLoadMore = false
      }
    },

    /**
     * Return user profile picture by gender
     * @param {string} searchInput
     */
    async searchIconClick(searchInput) {
      await this.resetState(searchInput)
    },

    /**
     * Handle click change status filter
     * @param {number} id - status id
     */
    async onFilter(param) {
      const { id, type } = param

      switch (type) {
        case 'Location':
          this.onFilterClickLocation(id)
          break

        case 'Status':
          this.onFilterClickStatus(id)
          break

        default:
          this.onFilterClickDepartments(id)
          break
      }
    },

    /**
     * Handle click change status filter
     * @param {number} id - status id
     */
    async onFilterClickStatus(id) {
      const isAlreadyExist = this.statusIds.indexOf(id)
      if (isAlreadyExist >= 0) {
        this.statusIds.splice(isAlreadyExist, 1)
      } else {
        this.statusIds.push(id)
      }
      this.resetState()
      await this.loadPeopleDataList()
    },

    /**
     * Handle click change location filter
     * @param {number || string} id - location id
     */
    async onFilterClickLocation(id) {
      if (id && id !== 'isRemote') {
        const isAlreadyExist = this.locationIds.indexOf(id)
        if (isAlreadyExist >= 0) {
          this.locationIds.splice(isAlreadyExist, 1)
        } else {
          this.locationIds.push(id)
        }
      } else if (id === 'isRemote') {
        if (this.isRemote) {
          this.isRemote = null
        } else {
          this.isRemote = 1
        }
      }
      this.resetState()
      await this.loadPeopleDataList()
    },

    /**
     * Handle click change department filter
     * @param {number} id - location id
     */
    async onFilterClickDepartments(id) {
      const isAlreadyExist = this.departmentIds.indexOf(id)
      if (isAlreadyExist >= 0) {
        this.departmentIds.splice(isAlreadyExist, 1)
      } else {
        this.departmentIds.push(id)
      }
      this.resetState()
      await this.loadPeopleDataList()
    },

    /**
     * Reset filter parameter
     * @param {bool} searchInput - input for search filter
     */
    async resetState(searchInput) {
      this.page = 0
      this.lastPage = 0
      this.setPeopleList([])
      await this.loadPeopleDataList(searchInput)
    },

    /**
     *  Function to sort table
     * @param {string} field - field type
     * @param {string} order - order type
     */
    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.resetState()
    },

    /**
     *  Handle for change pagination
     * @param {number} page - page number
     */
    onPageChange(page) {
      this.page = page
      this.loadPeopleDataList()
    },
    /**
     *  Function to reset filter
     */
    resetFilter() {
      this.page = 0
      this.setPeopleList([])
      this.isFilterOpen = false
      this.departmentIds = []
      this.statusIds = []
      this.isRemote = null
      this.locationIds = []
      this.loadPeopleDataList()
    },

    // mapFilterSort(isAscending) {
    //   if (
    //     this.sortField === 'users.first_name' &&
    //     isAscending &&
    //     this.sortOrder === 'asc'
    //   ) {
    //     return 'filter-active'
    //   } else if (
    //     this.sortField === 'users.first_name' &&
    //     !isAscending &&
    //     this.sortOrder === 'desc'
    //   ) {
    //     return 'filter-active'
    //   }
    // },

    /**
     *  Function to fetch peopla data list
     * @param {bool} isFiltering
     */
    async loadPeopleDataList(isFiltering) {
      this.isPeopleDataLoading = true

      try {
        const params = {
          perPage: this.perPage,
          sortField: this.sortField,
          sortOrder: this.sortOrder,
          departmentIds: this.departmentIds,
          statusIds: this.statusIds,
          locationIds: this.locationIds,
        }

        if (isFiltering) {
          params.isFiltering = true
          params.page = this.page
          params.isRemote = this.isRemote
          params.search = isFiltering
        } else {
          params.page = ++this.page
          params.isRemote = this.isRemote
        }

        const response = await this.actionFetchPeopleList(params)

        this.total = response.data.meta.total
        this.lastPage = response.data.meta.lastPage
      } catch (err) {
        console.log(err)
      }
      this.isPeopleDataLoading = false
    },

    /**
     *  Function to fetch filter list
     */
    async loadFilterList() {
      this.isFilterListLoading = true

      try {
        await this.actionFetchPeoplesFilter()
      } catch (err) {
        console.log(err)
      }
      this.isFilterListLoading = false
    },
  },

  watch: {
    selectedEmployee(row) {
      this.selectEmployee(row)
    },
  },
}
</script>
