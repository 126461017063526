<template>
  <div>
    <div class="level">
      <div class="level-left"></div>
      <div class="level-right">
        <div class="level-item">
          <b-button
            v-if="filter"
            icon-left="filter-variant"
            class="btn-filter-people"
            @click="filtered"
          >
            Filter
          </b-button>
        </div>

        <div class="level-item is-3">
          <div v-if="search">
            <SearchFilter
              :loadData="loadData"
              @onSearch="searchIconClick($event)"
            />
          </div>
        </div>
      </div>
    </div>

    <FilterVue
      :open="open"
      :pageMenu="'people'"
      @onReset="onReset"
      @onClose="filtered"
    >
      <template #body>
        <GeneralFilterRed
          v-for="item in filterData"
          @onFilter="onFilterClick($event)"
          :key="item.title"
          :filterList="item.list"
          :title="item.title"
          :ids="getIds(item.title)"
          :isRemote="isRemote"
        />
      </template>
    </FilterVue>
  </div>
</template>

<script>
import FilterVue from '../Filter.vue'
import SearchFilter from '../FilterContent/SearchFilter.vue'
import { mapGetters } from 'vuex'
import GeneralFilterRed from '../FilterContent/GeneralFilterRed.vue'

export default {
  components: {
    SearchFilter,
    FilterVue,
    GeneralFilterRed,
  },

  props: {
    filter: Boolean,
    loadData: Boolean,
    search: Boolean,
    open: Boolean,
    pageMenu: String,
    statusIds: Array,
    locationIds: Array,
    departmentIds: Array,
    isRemote: Number,
  },

  computed: {
    ...mapGetters({
      filterData: 'user/getPeoplesFilter',
    }),
  },

  methods: {
    /**
     * Emit state for open/close filter
     */
    filtered() {
      this.$emit('onFiltered')
    },

    /**
     * Emit handle click search user
     * @param event - event listener for search input
     */
    searchIconClick(evt) {
      this.$emit('onSearch', evt)
    },

    /**
     * Emit reset data
     */
    onReset() {
      this.$emit('onReset')
    },

    /**
     * Emit fetch people data
     */
    loadPeopleDataList() {
      this.$emit('onLoadPeopleData')
    },

    /**
     * Emit handle click for change filter by type filter
     * @param event - listener for change status filter
     */
    onFilterClick(event) {
      this.$emit('onFilter', event)
    },

    /**
     * Function to return list of id's by type
     * @param {string} type - filter type
     * @return {array}
     */
    getIds(type) {
      switch (type) {
        case 'Location':
          return this.locationIds

        case 'Status':
          return this.statusIds

        default:
          return this.departmentIds
      }
    },
  },
}
</script>

<style></style>
